body { margin: 0 }

.tiles {
	.Red { --c: red; --c2: white; }
	.Blue { --c: blue; --c2: white; }
	.Green { --c: Green; --c2: white; }
	.Black { --c: Black; --c2: white; }
	.White { --c: white; --c2: black; }

	.empty { --c: hsl(0, 0, 0.9); --c2: black; }

	display: block;
	position: relative;

	font-size: calc(100vw / 11);
	@media screen and (min-width: 33rem) {
		font-size: 3rem;
	}

	margin: 0.3em auto;
	width: 10.4em;

	text-align: center;
	caret-color: transparent;

	& > * {
		position: absolute;
		display: inline-block;
	}

	.tile {
		box-sizing: border-box;

		margin: 0.05em;
		width: 0.9em;
		height: 0.9em;
		line-height: 0.8em;

		border: 2px solid black;
		border-radius: 0.1em;

		transition: 0.4s;

		& > * {
			pointer-events: none;
		}

		&.empty {
			border-color: hsl(0, 0, 0.8);

			z-index: -1;
		}

		&.active {
			box-shadow: 0 0 0.2em 0.1em #5BF;
		}

		vertical-align: center;

		color: var(--c2);
		background: var(--c);

		span {
			display: block;
			font-size: 0.6em;
		}
	}

	.tile-first {
		left: 0em;
		top: 0em;

		--c: white;
		--c2: black;
	}

	.card {
		.tile();

		margin: 0.1em 0;
		right: 0;
		width: auto;
		line-height: 0.6em;
		padding: 0 0.2em;

		border-radius: 0.2em;

		span {
			display: inline;
			height: 100%;
			// font-size: 0.6em;
		}
	}

	.score {
		.card();

		background: #AAF;
		border-color: #88E;
	}

	.trash {
		.card();

		margin-top: 1.1em;

		background: #BBB;
		border-color: #888;
	}

	.player {
		z-index: -10;

		left: -0.1em;
		right: -0.1em;
		height: 6.1em;

		border-radius: 0.2em;

		&.active {
			text-decoration: underline;
			background: #CEF;
			box-shadow: 0 0 0.2em #CEF;
		}
	}

	h2 {
		font-size: 0.5em;
		line-height: 2em;
		margin: 0;
	}

	h1,a,button,label {
		position: relative;
		font-size: 1em;
		box-sizing: border-box;
		width: calc(100% - 0.4em);
		margin: 0.2em;
		padding: 0.1em;
	}

	label {
		display: flex;
		input {
			flex-grow: 1;
			width: 0;
			text-align: end;
			margin: 0 0 0 0.6em;
		}
	}

	.n {
		font-variant-numeric: tabular-nums;
	}

	.toast {
		border: 2px solid black;
		border-radius: 0.1em;
		padding: 0.2em;

		&.err {
			background: #F66;
		}
	}
}
