body {
  margin: 0;
}

.tiles {
  text-align: center;
  caret-color: #0000;
  width: 10.4em;
  margin: .3em auto;
  font-size: 9.09091vw;
  display: block;
  position: relative;
}

.tiles .Red {
  --c: red;
  --c2: white;
}

.tiles .Blue {
  --c: blue;
  --c2: white;
}

.tiles .Green {
  --c: Green;
  --c2: white;
}

.tiles .Black {
  --c: Black;
  --c2: white;
}

.tiles .White {
  --c: white;
  --c2: black;
}

.tiles .empty {
  --c: #e6e6e6;
  --c2: black;
}

@media screen and (width >= 33rem) {
  .tiles {
    font-size: 3rem;
  }
}

.tiles > * {
  display: inline-block;
  position: absolute;
}

.tiles .tile {
  box-sizing: border-box;
  vertical-align: center;
  color: var(--c2);
  background: var(--c);
  border: 2px solid #000;
  border-radius: .1em;
  width: .9em;
  height: .9em;
  margin: .05em;
  line-height: .8em;
  transition: all .4s;
}

.tiles .tile > * {
  pointer-events: none;
}

.tiles .tile.empty {
  z-index: -1;
  border-color: #ccc;
}

.tiles .tile.active {
  box-shadow: 0 0 .2em .1em #5bf;
}

.tiles .tile span {
  font-size: .6em;
  display: block;
}

.tiles .tile-first {
  --c: white;
  --c2: black;
  top: 0;
  left: 0;
}

.tiles .card {
  box-sizing: border-box;
  vertical-align: center;
  color: var(--c2);
  background: var(--c);
  border: 2px solid #000;
  border-radius: .2em;
  width: auto;
  height: .9em;
  margin: .1em 0;
  padding: 0 .2em;
  line-height: .6em;
  transition: all .4s;
  right: 0;
}

.tiles .card > * {
  pointer-events: none;
}

.tiles .card.empty {
  z-index: -1;
  border-color: #ccc;
}

.tiles .card.active {
  box-shadow: 0 0 .2em .1em #5bf;
}

.tiles .card span {
  height: 100%;
  font-size: .6em;
  display: inline;
}

.tiles .score {
  box-sizing: border-box;
  vertical-align: center;
  color: var(--c2);
  background: var(--c);
  background: #aaf;
  border: 2px solid #88e;
  border-radius: .2em;
  width: auto;
  height: .9em;
  margin: .1em 0;
  padding: 0 .2em;
  line-height: .6em;
  transition: all .4s;
  right: 0;
}

.tiles .score > * {
  pointer-events: none;
}

.tiles .score.empty {
  z-index: -1;
  border-color: #ccc;
}

.tiles .score.active {
  box-shadow: 0 0 .2em .1em #5bf;
}

.tiles .score span {
  height: 100%;
  font-size: .6em;
  display: inline;
}

.tiles .trash {
  box-sizing: border-box;
  vertical-align: center;
  color: var(--c2);
  background: var(--c);
  background: #bbb;
  border: 2px solid #888;
  border-radius: .2em;
  width: auto;
  height: .9em;
  margin: 1.1em 0 .1em;
  padding: 0 .2em;
  line-height: .6em;
  transition: all .4s;
  right: 0;
}

.tiles .trash > * {
  pointer-events: none;
}

.tiles .trash.empty {
  z-index: -1;
  border-color: #ccc;
}

.tiles .trash.active {
  box-shadow: 0 0 .2em .1em #5bf;
}

.tiles .trash span {
  height: 100%;
  font-size: .6em;
  display: inline;
}

.tiles .player {
  z-index: -10;
  border-radius: .2em;
  height: 6.1em;
  left: -.1em;
  right: -.1em;
}

.tiles .player.active {
  background: #cef;
  text-decoration: underline;
  box-shadow: 0 0 .2em #cef;
}

.tiles h2 {
  margin: 0;
  font-size: .5em;
  line-height: 2em;
}

.tiles h1, .tiles a, .tiles button, .tiles label {
  box-sizing: border-box;
  width: calc(100% - .4em);
  margin: .2em;
  padding: .1em;
  font-size: 1em;
  position: relative;
}

.tiles label {
  display: flex;
}

.tiles label input {
  text-align: end;
  flex-grow: 1;
  width: 0;
  margin: 0 0 0 .6em;
}

.tiles .n {
  font-variant-numeric: tabular-nums;
}

.tiles .toast {
  border: 2px solid #000;
  border-radius: .1em;
  padding: .2em;
}

.tiles .toast.err {
  background: #f66;
}
/*# sourceMappingURL=index.fbe93cd9.css.map */
